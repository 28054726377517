<template>
  <footer class="footer">
    Copyright © {{new Date().getFullYear()}} 无锡轻秒网络科技有限公司 版权所有 地址：无锡市新吴区菱湖大道200（B）栋333
    <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2023045483号-4</a>
    <router-link to="/about" target="_blank">关于我们</router-link>
  </footer>
</template>
<script>
export default {
    name: 'Footer',
    components: {},
    props: {

    },
    data() {
        return {

        }
    },
    methods: {

    },
    created() {

    }
}
</script>
<style scoped lang="less">
@import './index.less';
</style>