import Vue from 'vue'
import Main from './main.vue'

let PurchaseModalConstructor = Vue.extend(Main)

const PurchaseModal = function (options, params) {
  let instance = new PurchaseModalConstructor({
    ...options,
    data: {
      onClose: params?.onClose
    }
  })
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.visible = true
  return instance
}

export default PurchaseModal
