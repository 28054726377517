<template>
  <el-upload
    ref="uploadRef"
    class="upload_box"
    :drag="drag"
    action=""
    :accept="uploadAccept"
    :before-upload="handleUpload"
    :limit="limit"
    :show-file-list="false"
  >
    <slot name="default"></slot>
  </el-upload>
</template>
<script>
import FileType from 'file-type/browser'
import { getImageFileSize, uploadOSS } from '@/utils'
import { Loading, Message } from 'element-ui'
import { imgCut } from '@/api'
import { v4 as uuid } from 'uuid'

export default {
  name: 'FileUpload',
  components: {},
  props: {
    memory: {
      type: Number,
      default: 25 * 1024 * 1024
    },
    maxWidth: {
      type: Number,
      default: 4096
    },
    maxHeight: {
      type: Number,
      default: 4096
    },
    accept: {
      type: Array,
      default: function () {
        return ['image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/bmp', 'image/gif']
      }
    },
    limit: {
      type: Number,
      default: 1
    },
    drag: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      default: function () {
        return () => {}
      }
    },
    onFail: {
      type: Function,
      default: function () {
        return () => {}
      }
    }
  },
  data() {
    return {
      // uploadAccept:
      size: {
        width: 0,
        height: 0
      }
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters['userStore/isLogin']
    },
    uploadAccept() {
      return this.props?.accept?.join(',')
    }
  },
  methods: {
    /**
     * @description 手动触发上传
     */
    manualUpload() {
      //这里通过元素选择器来手动触发点击事件
      this.$refs.uploadRef.$el.querySelector('.upload_content').click()
    },
    handleLogin() {
      this.$loginModal({
        $store: this.$store,
        $router: this.$router,
        $route: this.$route,
        onHandleClose: () => {
          this.$messageDialog({
            showLeft: true,
            showRight: true,
            leftBtnTxt: '退出',
            rightBtnTxt: '继续登录',
            title: '温馨提示',
            content: `确认退出吗？微信扫码登录即可享限时活动哦～`,
            onClose: () => {},
            onClickLeftBtn: () => {},
            onClickRightBtn: () => {
              this.handleLogin()
            }
          })
        }
      })
    },
    openPurchaseModal() {
      this.$purchaseModal({
        onClose: () => {
          if (this.$store.state.userStore.allCert?.has_image_count === 0) {
            this.$retentionModal()
          }
        }
      })
    },
    /**
     * @description 检查是否允许上传
     * @param file
     * @returns {Promise<boolean>}
     */
    async checkFile(file) {
      const res = await FileType.fromBlob(file)
      const mime = res?.mime

      if (!this.isLogin) {
        this.handleLogin()
        return false
      }

      const isTypeAccess = this.accept.includes(mime)

      if (!isTypeAccess) {
        this.$messageDialog({
          showLeft: true,
          showRight: true,
          leftBtnTxt: '取消',
          rightBtnTxt: '重新上传',
          title: '温馨提示',
          content: `格式不支持！`,
          onClose: () => {},
          onClickLeftBtn: () => {},
          onClickRightBtn: () => {
            this.manualUpload()
          }
        })
        return false
      }
      const isSizeAccess = file.size < this.memory

      if (!isSizeAccess) {
        this.$messageDialog({
          showLeft: true,
          showRight: true,
          leftBtnTxt: '取消',
          rightBtnTxt: '重新上传',
          title: '温馨提示',
          content: `文件大小不得超过${this.memory / 1024 / 1024}Mb`,
          onClose: () => {},
          onClickLeftBtn: () => {},
          onClickRightBtn: () => {
            this.manualUpload()
          }
        })
        return false
      }
      let { width, height } = await getImageFileSize(file)
      const isWidthOrHeightAccess = width <= this.maxWidth && height <= this.maxHeight
      if (!isWidthOrHeightAccess) {
        this.$messageDialog({
          showLeft: true,
          showRight: true,
          leftBtnTxt: '取消',
          rightBtnTxt: '重新上传',
          title: '温馨提示',
          content: `尺寸超过${this.maxWidth}*${this.maxHeight}！`,
          onClose: () => {},
          onClickLeftBtn: () => {},
          onClickRightBtn: () => {
            this.manualUpload()
          }
        })
        return false
      }
      this.size = {
        width: width,
        height: height
      }
      // 用户没有试用次数 & 没有转换次数
      if (
        this.$store.state.userStore.allCert?.image_audio_convert_count === 0 &&
        this.$store.state.userStore.allCert?.has_image_count === 0
      ) {
        this.openPurchaseModal()
        return false
      }

      return true
    },
    /**
     * @description 触发上传
     * @param file
     */
    async handleUpload(file) {
      let flag = await this.checkFile(file)
      if (flag) {
        const loadingInstance = Loading.service({
          background: 'rgba(0,0,0,.5)',
          text: 'AI处理中...'
        })
        try {
          // this.onSuccess({
          //   originFile: file,
          //   size: this.size,
          //   source: 'http://localhost:8080/target.png'
          // })
          //
          // return false
          // 获取文件后缀
          var fileExtension = file.name?.split('.')?.pop()
          let date = new Date()
          let dirName = `${date.getFullYear()}${
            date.getMonth() + 1 > 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
          }${date.getDate() > 10 ? date.getDate() : '0' + date.getDate()}`
          const newFileName = `miaokou/${dirName}/${uuid()}.${fileExtension}`
          let res = await uploadOSS(newFileName, file)
          if (res?.url) {
            const source = res.url
            let imgCutRes = await imgCut(source)
            if (imgCutRes.data.status === 5001) {
              // 没有试用机会
              this.openPurchaseModal()
            } else {
              if (imgCutRes.data.data.code === 0) {
                const token = imgCutRes.data.data.dl_token
                await this.$store.dispatch('userStore/updateDownloadToken', token)
                await this.$store.dispatch('userStore/updateAllCert')
                const src = imgCutRes.data.data.data.imageUrl
                let { width, height } = await getImageFileSize(src)
                localStorage.setItem('isDownload', 'false')
                if (typeof this.onSuccess === 'function') {
                  this.onSuccess({
                    source: src,
                    originFile: file,
                    size: {
                      width,
                      height
                    }
                  })
                }
              } else {
                Message.error('图片处理失败！')
                if (typeof this.onFail === 'function') {
                  this.onFail()
                }
              }
            }

          } else {
            Message.error('图片上传失败！')
          }
        } catch (e) {
          Message.error('图片上传失败！')
        } finally {
          loadingInstance.close()
        }
      }

      return false
    }
  },
  created() {}
}
</script>
<style scoped lang="less"></style>
