<template>
  <header class="header_area">
    <div class="header_container">
      <div class='header_left'>
        <a href='/' target='_self'>
          <img src="@/assets/img/logo.svg" alt="" class="logo" />
        </a>
        <slot name='nav'></slot>
      </div>
      <slot name="panel"></slot>
      <div class="header_right">
        <slot name="btn"></slot>
        <div class='buyBtnBox' @click='handleBuy'>
          <button class='buyPackageBtn'>购买张数</button>
          <img src='https://res.yunkun.cn/img_geshicn/img/activity.svg' alt='' class='activity_img'>
        </div>
        <div class="userContainer">
          <div class='loginBox' v-if="!isLogin" >
            <button class="loginBtn" @click="handleLogin">
              <span>登录/注册</span>
            </button>
            <img src="@/assets/img/login_tips.svg" class="tips" alt="" />
          </div>

          <UserInfo class="userInfoBox" v-else />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from 'vuex'
import UserInfo from '@/components/UserInfo/index.vue'

export default {
  name: 'Header',
  components: {
    UserInfo
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      isLogin: 'userStore/isLogin'
    })
  },
  methods: {
    openPurchaseModal() {
      this.$purchaseModal({
        onClose: () => {
          if (this.$store.state.userStore.allCert?.has_image_count === 0) {
            this.$retentionModal()
          }
        }
      })

    },
    handleBuy() {
      if (!this.$store.getters['userStore/isLogin']) {
        this.handleLogin()
        return
      }
      this.openPurchaseModal()
    },
    handleLogin() {
      this.$loginModal({
        $store: this.$store,
        $router: this.$router,
        $route: this.$route,
        onHandleClose: () => {
          this.$messageDialog({
            showLeft: true,
            showRight: true,
            leftBtnTxt: '退出',
            rightBtnTxt: '继续登录',
            title: '温馨提示',
            content: `确认退出吗？微信扫码登录即可享限时活动哦～`,
            onClose: () => {},
            onClickLeftBtn: () => {},
            onClickRightBtn: () => {
              this.handleLogin()
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
@import './index.less';
</style>
