<template>
  <el-dialog
    class="loginBox"
    :visible.sync="visible"
    width="780px"
    :append-to-body="true"
    :show-close="false"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'loginContainer'"
    @open="onDialogOpen"
    @close="handleClose"
    :destroy-on-close="true"
  >
    <div class="loginMain">
      <i class="el-icon-close closeIcon" @click="handleClickClose"></i>
      <div class="mainLeft"></div>
      <div class="mainRight">
        <div class="rightTitle">
          <span class="wxIcon"></span>
          微信扫码登录注册
        </div>
        <div class="qrCodeWrapper">
          <div class="loadingBox" v-if="isLoading"></div>
          <div class="qrcodeContentBox" v-else>
            <img v-if="qrCodeStatus === QR_CODE_STATUS.SUCCESS" :src="loginQrCodeUrl" alt="" style="width: 100%" />
            <div class="qrCodeStatusBox" v-else>
              <div class="qrCodeStatusContent">
                <span class="refreshBtn" @click="onRefreshQrCode"></span>
                <span class="txt">{{ qrCodeStatus === QR_CODE_STATUS.EXPIRE ? '二维码已过期' : '获取失败' }}</span>
              </div>
            </div>
          </div>
        </div>
        <p class="tips">微信扫一扫关注公众号登录，1步极速注册登录</p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getLoginQrCode, getLoginStatus } from '@/api'
import { Message } from 'element-ui'
import { trackingLogin, setToken } from '@/utils'
import { mapActions, mapGetters, mapState } from 'vuex'
import { trackRegister } from '@/api/track'

const QR_CODE_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  EXPIRE: 'expire'
}
export default {
  name: 'LoginModal',
  data() {
    return {
      QR_CODE_STATUS,
      qrCodeStatus: QR_CODE_STATUS.FAIL,
      loginQrCodeUrl: '',
      isLoading: false,
      visible: false,
      onClose: () => {},
      onHandleClose: () => {},
      checkLoginStatusTimer: null
    }
  },
  methods: {
    ...mapActions({
      setUserInfo: 'userStore/setUserInfo'
    }),
    handleClickClose() {
      if (typeof this.onHandleClose === 'function') {
        this.onHandleClose()
      }

      this.handleClose()
    },
    handleClose() {
      clearTimeout(this.checkLoginStatusTimer)
      this.visible = false
      if (typeof this.onClose === 'function') {
        this.onClose()
      }
      this.$destroy()
    },
    /**
     * @description 轮询二维码状态
     * @param ticket 凭证
     * @param expire_seconds 有效期，单位：秒
     * @param startTime 轮询开始时间
     */
    loopCheckLoginStatus(ticket, expire_seconds, startTime) {
      clearTimeout(this.checkLoginStatusTimer)
      let currentTime = new Date().getTime()
      if ((currentTime - startTime) / 1000 > expire_seconds) {
        // TODO: 二维码已过期
        this.qrCodeStatus = QR_CODE_STATUS.EXPIRE
        return
      }
      this.checkLoginStatusTimer = setTimeout(async () => {
        let r = await getLoginStatus(ticket)
        if (r.data.status === 0) {
          // TODO: 更新token，更新用户信息
          setToken(r.data.data)
          await this.setUserInfo(r.data.data)
          await this.$store.dispatch('userStore/updateAllCert')
          trackRegister().catch(e => {})
          this.handleClose()
          Message({
            type: 'success',
            message: '登录成功！'
          })

          clearTimeout(this.checkLoginStatusTimer)
        } else {
          this.loopCheckLoginStatus(ticket, expire_seconds, startTime)
        }
      }, 1500)
    },
    /**
     * @description 获取登录二维码
     * @returns {Promise<void>}
     */
    async handleGetLoginQrCode() {
      this.isLoading = true
      try {
        let res = await getLoginQrCode()
        if (res.data.status === 0) {
          let { ticket, expire_seconds } = res.data.data
          if (ticket) {
            let src = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticket}`
            let startTime = new Date().getTime()
            // 二维码获取成功
            this.qrCodeStatus = QR_CODE_STATUS.SUCCESS
            this.loginQrCodeUrl = src
            this.loopCheckLoginStatus(ticket, expire_seconds, startTime)
          } else {
            // 获取二维码失败
            this.qrCodeStatus = QR_CODE_STATUS.FAIL
          }
        } else {
          // 获取二维码失败
          this.qrCodeStatus = QR_CODE_STATUS.FAIL
        }
      } catch (e) {
        this.qrCodeStatus = QR_CODE_STATUS.FAIL
      } finally {
        this.isLoading = false
      }
    },
    /**
     * @description 刷新登录二维码
     * @returns {Promise<void>}
     */
    async onRefreshQrCode() {
      await this.handleGetLoginQrCode()
    },
    /**
     * @description 当弹窗打开的时候调用
     * @returns {Promise<void>}
     */
    async onDialogOpen() {
      await this.handleGetLoginQrCode()
    }
  }
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
