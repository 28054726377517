export const Rights = [
  '批量抠商品',
  '批量抠人像',
  '一键智能抠图',
  '发丝级AI抠图',
  '证件照换背景色',
  '自定义添加背景',
  '高清无水印下载'
]

export const DefaultPurchase = [
  {
    id: 38,
    title: '1张',
    desc: '体验首选',
    price: '8.9',
    expired: '永久有效',
    origin_price: '原价¥1.9/张',
    defaultActive: true,
    corner: '',
    discountPrice: '200'
  },
  {
    id: 36,
    title: '10张',
    desc: '1.99元/张',
    price: '19.9',
    expired: '永久有效',
    origin_price: '原价¥1.9/张',
    defaultActive: false,
    corner: '',
    discountPrice: '200'
  },
  {
    id: 29,
    title: '100张',
    desc: '0.39元/张',
    price: '39',
    expired: '有效期1个月',
    origin_price: '原价¥1.9/张',
    defaultActive: false,
    corner: '',
    discountPrice: '200'
  },
  {
    id: 30,
    title: '300张',
    desc: '0.163元/张',
    price: '49',
    expired: '有效期1年',
    origin_price: '原价¥1.5/张',
    defaultActive: false,
    corner: '限时买200送100',
    discountPrice: '200'
  },
  {
    id: 31,
    title: '1000张',
    desc: '0.099元/张',
    price: '99',
    expired: '永久有效',
    origin_price: '原价¥0.9/张',
    defaultActive: false,
    corner: '',
    discountPrice: '200'
  },
  {
    id: 32,
    title: '5000张',
    desc: '每张仅需6分钱',
    price: '299',
    expired: '永久有效',
    origin_price: '原价¥0.5/张',
    defaultActive: false,
    corner: '最划算',
    discountPrice: '200'
  },



]

export const RetentionPackage = {
  id: 33,
  title: '10张抠图套餐（终身）',
  desc: '0.99元/张',
  price: '9.9',
  origin_price: '原价¥1.9/张',
  defaultActive: false,
  corner: '',
  discountPrice: '200'
}
