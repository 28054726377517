export const pxToMm = px => {
  // 1 inch = 25.4 mm
  return (px / (window.devicePixelRatio * 96)) * 25.4
}

// 将像素(px)转换为英寸(in)
export const pxToIn = px => {
  const mm = (px / (window.devicePixelRatio * 96)) * 25.4
  return mm / 25.4
}

// 将毫米(mm)转换为像素(px)
export const mmToPx = mm => {
  return (mm * window.devicePixelRatio) / 25.4
}

// 将英寸(in)转换为像素(px)
export const inToPx = inch => {
  return inch * window.devicePixelRatio
}
