import Vue from 'vue'
import * as buffer from 'buffer'

if (typeof window.global === 'undefined') {
  window.global = window
}
if (typeof window.Buffer === 'undefined') {
  window.Buffer = buffer.Buffer
}
import '@/assets/less/init.less'
import 'element-ui/lib/theme-chalk/index.css'
import MetaInfo from 'vue-meta-info'

import MessageDialog from '@/components/MessageDialog'
import LoginModal from '@/components/LoginModal'
import PurchaseModal from '@/components/PurchaseModal'

import {
  Carousel,
  CarouselItem,
  Upload,
  Dialog,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  Slider,
  Select,
  Option,
  Tooltip,
  Loading
} from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import RetentionModal from '@/components/RetentionModal'


Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Upload.name, Upload)
Vue.component(Dialog.name, Dialog)
Vue.component(Input.name, Input)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Radio.name, Radio)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Slider.name, Slider)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Tooltip.name, Tooltip)
Vue.use(MetaInfo)

Vue.use(Loading.directive)

Vue.use(LoginModal, {
  store,
  router
})
Vue.use(PurchaseModal, {
  store,
  router
})
Vue.use(RetentionModal, {
  store,
  router
})
Vue.use(MessageDialog)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
