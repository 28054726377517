<template>
  <el-dialog
          class="purchaseBox"
          :close-on-click-modal="false"
          :visible.sync="visible"
          width="480px"
          :append-to-body="true"
          :show-close="false"
          :custom-class="'purchaseWrapper'"
          :modal-append-to-body="false"
          :close-on-press-escape="false"
          :lock-scroll="false"
          @open="onOpenDialog"
          @close="handleClose"
          :destroy-on-close="true"
  >
    <div class="purchaseModalMain">
      <i class="closeIcon el-icon-close" @click="handleClose"></i>
      <img src="@/assets/img/retentionPopBg.png" alt="" class="popBg" />
      <div class="title">{{ titleTxt }}</div>
      <div class="contentBox">
        <span class="weight">{{ purchaseTitle }}</span>
        <span class="priceBtn">
          <span class="unit">¥</span>
          <span>{{ price }}</span>
        </span>
      </div>
      <span class="price">
        <span class="unit">¥</span>
        <span>{{ price }}</span>
      </span>
      <div class="descList">
        <div class="listItem" v-for='(item, idx) in rights' :key='idx'><span class="icon"></span>{{item.txt}}</div>
      </div>
      <div class="payTitle">扫码立即开通</div>
      <div class="payChannelBox">
        <span class="wechat_icon"></span>
        <span class="ali_icon"></span>
        <span>扫码支付</span>
      </div>
      <div class="qrCodeBox">
        <img :src="qrCodeSrc" alt="" class="qrCodeImg" v-if="!loading" />
        <div class="loading" v-else>
          <img src="@/assets/img/loading.png" alt="" class="loadingImg" />
        </div>
      </div>
      <div class="closeBtn" @click="handleClose">放弃机会</div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import QRCode from 'qrcode'
import { createOrder, getPayStatus } from '@/api'
import { Message } from 'element-ui'
import { RetentionPackage } from '@/components/RetentionModal/src/config'
import { trackOrder } from '@/api/track'
export default {
  name: 'RetentionModal',
  components: {},
  data() {
    return {
      RetentionPackage,
      loading: false,
      qrCodeSrc: '',
      price: 0,
      visible: false,
      pollCount: 0, // 轮询次数
      payResultTimer: null,
      titleTxt: '', //
      purchaseTitle: '',
      currentPackage: {},
      rights: [],
      checkID: '',
      beginTime: new Date().getTime(),
      expiredTime: 1 * 60 * 1000
    }
  },
  computed: {
    ...mapGetters({
      isVip: 'userStore/isVIP',
      isCountVIP: 'userStore/isCountVIP',
    }),
  },
  methods: {
    // 获取商品信息
    async getPurchase() {
      this.currentPackage = this.RetentionPackage
      this.price = this.currentPackage.price
      this.titleTxt = this.currentPackage.title
      this.purchaseTitle = this.currentPackage.desc
      this.rights = this.currentPackage.rights
      await this.checkPurchase(this.currentPackage.id)

    },
    /**
     * @description 转换二维码
     * @param path 路径信息
     * @returns {Promise<unknown>}
     */
    async toQRCode(path) {
      return QRCode.toDataURL(path)
    },
    /**
     * @description 轮询，获取支付状态
     * @param orderId 订单ID
     */
    lookup(orderId) {
      clearTimeout(this.payResultTimer)

      // if (this.pollCount > 5000) {
      //   // 支付超时
      //   this.hideRetentionModal()
      //   return
      // }

      if (new Date().getTime() - this.beginTime >= this.expiredTime) {
        this.checkPurchase(this.checkID)
        return
      }

      this.payResultTimer = setTimeout(async () => {
        let r = await getPayStatus(orderId)
        if (r.data.status === 0 && r.data.data.order.status === 1) {
          // 查询成功 并且 状态为1 或者支付超时
          // 查询用户权益
          await this.$store.dispatch('userStore/updateAllCert')
          let price_360 = Math.round(this.price * 100);
          trackOrder(orderId, price_360).catch(e => {})
          Message({
            type: 'success',
            message: '支付成功'
          })
          clearTimeout(this.payResultTimer)
          this.hideRetentionModal()

        } else {
          this.lookup(orderId)
        }
      }, 1500)

      this.pollCount++ // 增加轮询次数
    },
    /**
     * @description 切换套餐
     * @param idx 选中的索引
     * @returns {Promise<void>}
     */
    async checkPurchase(id) {
      this.loading = true
      this.checkID = id;
      let res = await createOrder(id)
      if (res.data.status === 0) {
        this.beginTime = new Date().getTime()
        let src = await this.toQRCode(res.data.data)
        this.qrCodeSrc = src
        this.loading = false
        this.pollCount = 0 // 清空轮询次数
        let s = res.data.data.split('/')
        let orderId = s[s.length - 1]
        this.lookup(orderId)
      } else {
        // 二维码获取失败
      }
    },
    /**
     * @description 关闭窗口
     */
    handleClose() {
      clearInterval(this.payResultTimer)
      this.hideRetentionModal()
    },
    /**
     * @description 打开窗口
     */
    onOpenDialog() {
      this.getPurchase()
    },
    hideRetentionModal() {
      this.visible = false
      if (typeof this.onClose === 'function') {
        this.onClose()
      }
      this.$destroy()
    }
  }
}
</script>
<style scoped lang="less">
@import './main.less';
</style>
