import Vue from 'vue'
import Main from './main.vue'

let MessageConstructor = Vue.extend(Main)

const Message = function (options) {
  let instance = new MessageConstructor({
    data: options
  })
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.visible = true
  return instance
}

export default Message
