export default {
  namespaced: true,
  state: () => ({
    originWidth: 0, // 原始图片宽度
    originHeight: 0, // 原始图片高度
    originSrc: '', // 原始图片路径
    dragCanvasInstance: {
      originWidth: 0,
      originHeight: 0
    },
    editor: {
      width: 0,
      height: 0,
      scaleX: 1,
      scaleY: 1,
      originWidth: 0,
      originHeight: 0,
      rotate: 0
    },
    imgInstance: {
      width: 0,
      height: 0,
      scaleX: 0,
      scaleY: 0,
      originHeight: 0,
      originWidth: 0,
      rotate: 0
    },
    backgroundRectInstance: {
      width: 0,
      height: 0,
      scaleX: 0,
      scaleY: 0,
      originHeight: 0,
      originWidth: 0,
      rotate: 0
    }
  }),
  mutations: {
    setOriginInfo(state, { src, width, height, editorWidth, editorHeight }) {
      state.originWidth = width
      state.originHeight = height
      state.originSrc = src
      state.editor = {
        width: editorWidth,
        height: editorHeight
      }
    },
    setDragCanvasInstance(state, payload) {
      state.dragCanvasInstance = payload
    }
  },
  actions: {
    setOriginInfo({ commit }, payload) {
      commit('setOriginInfo', payload)
    },
    setDragCanvasInstance({ commit }, payload) {
      commit('setDragCanvasInstance', payload)
    },
    /**
     * @descrition 保存草稿
     */
    saveDraft() {}
  }
}
