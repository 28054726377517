import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home'

Vue.use(VueRouter)
const baseTdk = {
  title: '秒抠-在线一键抠图换背景_自动识别抠图软件_秒抠AI智能抠图',
  description:
    '秒抠在线抠图工具，无需下载即可实现在线抠图及图片处理，自动清除背景，支持智能抠图、证件照换背景、手动抠图等功能。miaokou.cn',
  keywords: '抠图软件,一键抠图,AI智能抠图,秒抠'
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      tdk: baseTdk
    }
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import('@/views/editor'),
    meta: {
      tdk: baseTdk
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about'),
    meta: {
      tdk: baseTdk
    }
  },
  {
    path: '/edit-goods',
    name: 'editGoods',
    component: () => import('@/views/edit-goods'),
    meta: {
      tdk: baseTdk
    }
  },
  {
    path: '/edit-people',
    name: 'editPeople',
    component: () => import('@/views/edit-goods'),
    meta: {
      tdk: baseTdk
    }
  },
  {
    path: '/edit-photo',
    name: 'edit-photo',
    component: () => import('@/views/edit-photo'),
    meta: {
      tdk: baseTdk
    }
  },
  {
    path: '*',
    name: 'NotFound',
    redirect: () => '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  }
})

export default router
