<template>
  <div class="userInfoWrapper">
    <img src="@/assets/img/avatar.svg" alt="" class="avatar" />
    <div class="userInfoContainer">
      <div class="userInfoBox">
        <img src="@/assets/img/avatar.svg" alt="" class="userAvatar" />
        <div class="userInfoBoxRight">
          <div class="nickname">{{ '轻秒' + allCert?.uid }}</div>
          <div class="user">
<!--            <span class="tag" v-if="isVip">免费用户</span>-->
<!--            <span class="vipTag" v-else>VIP会员</span>-->
            <div class="userIdBox">
              <span class="userId">ID: {{ allCert?.uid }}</span>
              <span class="copyBtn" :data-clipboard-text="allCert?.uid"></span>
            </div>
          </div>
        </div>
      </div>
<!--      <ul class="entitlementTable">-->
<!--        <li>单次上传不超过10个</li>-->
<!--        <li>不限文件大小</li>-->
<!--      </ul>-->
      <div class="residualEquity">
        <div class="countBox">
          <span class="count">剩余张数：{{allCert?.has_image_count}}张</span>
          <span v-if='!!allCert?.has_image_count'>到期时间：永久</span>
        </div>
        <button class="purchaseButton" @click="openPurchaseModal">购买张数</button>
      </div>
      <button @click="onLogout" class="logoutBtn">退出登录</button>
    </div>
  </div>
</template>
<script>
import ClipboardJS from 'clipboard'
import { Message } from 'element-ui'
import { mapActions } from 'vuex'

export default {
  name: 'UserInfo',
  props: {},
  data() {
    return {
      clipboard: null
    }
  },
  computed: {
    allCert() {
      return this.$store.state.userStore.allCert
    }
  },
  methods: {
    ...mapActions({
      logout: 'userStore/logout'
    }),
    onLogout() {
      this.logout()
      if (this.$route.name !== 'home') {
        this.$router.push('/')
      }
    },
    handleLogin() {
      this.$loginModal({
        $store: this.$store,
        $router: this.$router,
        $route: this.$route,
        onHandleClose: () => {
          this.$messageDialog({
            showLeft: true,
            showRight: true,
            leftBtnTxt: '退出',
            rightBtnTxt: '继续登录',
            title: '温馨提示',
            content: `确认退出吗？微信扫码登录即可享限时活动哦～`,
            onClose: () => {},
            onClickLeftBtn: () => {},
            onClickRightBtn: () => {
              this.handleLogin()
            }
          })
        }
      })
    },
    openPurchaseModal() {
      if (!this.$store.getters['userStore/isLogin']) {
        this.handleLogin()
        return
      }
      this.$purchaseModal({
        onClose: () => {
          if (this.$store.state.userStore.allCert?.has_image_count === 0) {
            this.$retentionModal()
          }
        }
      })
    }
  },
  mounted() {
    this.clipboard = new ClipboardJS('.copyBtn')
    this.clipboard.on('success', function (e) {
      e.clearSelection()
      Message({
        type: 'success',
        message: '复制成功'
      })
    })

    this.clipboard.on('error', function () {
      Message({
        type: 'error',
        message: '复制失败'
      })
    })
  },
  destroyed() {
    typeof this.clipboard?.destroyed === 'function' && typeof this.clipboard?.destroyed()
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
