import axios from 'axios'
import authAxios from '@/axios/authAxios'
import {md5} from 'js-md5'

export const trackRegister = async () => {
  try {
    await authAxios.post(
      '/imageformat/login_baidu',
      {
        bdVID: localStorage.getItem('bd_vid')
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  } catch (e) {
    console.log('上报失败： ' + e)
  }
  try {
    await axios.post("/api/upload360data", {
      data: {
        data_industry: "ocpc_ps_convert",
        data_detail: {
          qhclickid: window.localStorage.getItem("qhclickid"),
          event: "REGISTERED"
        }
      }
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  } catch (e) {
    console.log("上报失败： " + e);
  }
}

export const trackOrder = async (orderId, price) => {
  try {
    await authAxios.post(
      '/imageformat/order_baidu',
      {
        bdVID: localStorage.getItem('bd_vid'),
        amount: price
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  } catch (e) {
    console.log('上报失败： ' + e)
  }
  try {
    await axios.post("/api/upload360data", {
      data: {
        data_industry: "ocpc_ps_convert",
        data_detail: {
          qhclickid: window.localStorage.getItem("qhclickid"),
          trans_id: md5(orderId),
          event: "ORDER",
          event_param: {
            value: price
          }
        }
      }
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  } catch (e) {
    console.log("上报失败： " + e);
  }
}
