export const RetentionPackage =   {
  id: 33,
  title: '还在犹豫？先体验一下',
  desc: '10张抠图套餐',
  price: '9.9',
  rights: [
    {
      txt: '自动消除背景',
    },
    {
      txt: '自由裁剪大小'
    }
  ]
}