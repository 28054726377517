<template>
  <div id="app" @contextmenu="disableRightClick">
    <router-view></router-view>
    <div class="contactBox">
      <img src="@/assets/img/service.svg" alt="" class="contact_icon" />
      <div class="contact_qr_code_box">
        <img src="https://res.yunkun.cn/img_geshicn/img/contact_qr_code.png" class="contact_qr_code" alt="" />
        <div class="txt">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2" fill="none">
            <path d="M0 1H10" stroke="#A0ACBB" stroke-width="0.5" stroke-dasharray="2 2" />
          </svg>
          <span>微信扫码咨询</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2" fill="none">
            <path d="M0 1H10" stroke="#A0ACBB" stroke-width="0.5" stroke-dasharray="2 2" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils'
import { mapActions } from 'vuex'

export default {
  name: 'APP',
  methods: {
    ...mapActions({
      updateAllCert: 'userStore/updateAllCert',
      logout: 'userStore/logout'
    }),
    disableRightClick(e) {
      e.preventDefault()
    }
  },
  created() {
    let { bd_vid } = this.$route.query
    let { qhclickid } = this.$route.query;
    if (bd_vid) {
      window.localStorage.setItem('bd_vid', bd_vid)
    }

    if (qhclickid) {
      window.localStorage.setItem("qhclickid", qhclickid);
    }
    if (!!getToken()) {
      this.updateAllCert()
    } else {
      this.logout()
    }
  }
}
</script>

<style lang="less" scoped>
.contactBox {
  position: fixed;
  bottom: 100px;
  right: 40px;
  z-index: 9;
  padding: 12px 12px 7px 12px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    .contact_qr_code_box {
      display: block;
    }
  }

  .contact_qr_code_box {
    display: none;
    position: absolute;
    transform: translateX(-190px);
    bottom: 0;
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #d9dde4;
    background: #fff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

    .contact_qr_code {
      width: 120px;
      height: 120px;
    }
    .txt {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      font-size: 14px;
      color: #1b1f25;

      span {
        margin: 0 8px;
      }
    }
  }
}
</style>